<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="activity-page">
    <b-card class="" title="Purple Scribe Sessions">
      <b-btn
        v-if="$can('Create', 'Scribe')"
        variant="success"
        class=" float-right" style="margin-top: -10px;"
        @click="showScribeModal('modal-create-scribe-job')"
      >
        Create Scribe Session
      </b-btn>
      <b-card-text>
        This page shows all Purple Scribe sessions and their status.
      </b-card-text>
    </b-card>
    <b-card no-body class="mb-0">
      <div class="row px-2 pt-1 justify-content-end">
        <section class="col-3">
          <label>Search</label>
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              @keydown.enter="getMoreJobs(1)"
              class="d-inline-block" placeholder="Search session title or unique ID..."
            />
            <b-input-group-append>
              <b-button @click="getMoreJobs(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </section>

        <section class="col-2">
          <label>Status</label>
          <b-form-select @change="getMoreJobs(1)" v-model="filters.status" class="mb-2">
            <b-form-select-option value="">All</b-form-select-option>
            <b-form-select-option value="Awaiting">Awaiting File</b-form-select-option>
            <b-form-select-option value="Processing">Processing</b-form-select-option>
            <b-form-select-option value="Complete">Complete</b-form-select-option>
            <b-form-select-option value="Failed">Failed</b-form-select-option>
          </b-form-select>
        </section>

        <section class="col-2">
          <label>Session type</label>
          <b-form-select @change="getMoreJobs(1)" v-model="filters.job_type" class="mb-2">
            <b-form-select-option value="">All</b-form-select-option>
            <b-form-select-option value="General">General</b-form-select-option>
            <b-form-select-option value="Keyworker">Keyworker</b-form-select-option>
            <b-form-select-option value="AssuranceAllianceActionPlan">Assurance Alliance Action Plan</b-form-select-option>
            <b-form-select-option value="SkillsPassport">Skills Passport</b-form-select-option>
            <b-form-select-option value="QAndA">Q&A</b-form-select-option>
            <b-form-select-option value="DST">DST</b-form-select-option>
            <b-form-select-option value="PurpleVisits">Purple Visits</b-form-select-option>
            <b-form-select-option value="HealthcareAssessment">Healthcare Assessment</b-form-select-option>
          </b-form-select>
        </section>

        <section class="col-2" v-if="$can('ReadAll', 'Scribe')">
          <label>Created by</label>
          <model-list-select :list="users"
                             @input="searchUsers"
                             :class="{'disabled': users.length === 0}"
                             v-model="filters.user_uid"
                             option-value="uid"
                             option-text="name"
                             clearable
                             placeholder="Select user"/>
        </section>

        <div class="ui-spacer"></div>

        <section class="col-2 d-flex justify-content-end align-items-center">
          <b-button @click="clearFilters()">Clear filters</b-button>
        </section>
      </div>
      <section>
        <b-table
          class="data-table"
          hover
          :items="jobs"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(job_type)="data">
            <p>{{data.item.job_type | splitCamelCase | capEachWord}}</p>
          </template>
          <template #cell(unique_id)="data">
            <!-- Supports old version of meta data-->
            <span v-if="data.item.meta.prison_number">
              {{data.item.meta.prison_number}}
            </span>
            <span v-else>
              {{data.item.meta.unique_identifier}}
            </span>
          </template>
          <template #cell(meta.recorded_at)="data">
            {{data.item.meta.recorded_at | formatDateTime}}
          </template>
          <template v-if="$can('Read', 'Scribe')" #cell(actions)="data">
            <router-link :to="{ name: 'purple-scribe-details', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
              View session
            </router-link>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreJobs" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <p v-if="total_count === 0" class="col-6 ml-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-create-scribe-job" title="Create a scribe session"  hide-footer no-close-on-backdrop>
      <CreateScribeJobModal @close="closeModals" @refresh="refreshScribeJobs"/>
    </b-modal>

  </div>
</template>
<!--eslint-enable-->

<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import PurpleScribeService from '@/services/PurpleScribeService';
import CreateScribeJobModal from "@/views/purpleScribe/sections/CreateScribeJobModal.vue";
import UserService from "@/services/UserService";
import {ModelListSelect} from "vue-search-select";

export default {
  name: 'Activities',
  components: {
    CreateScribeJobModal,
    ModelListSelect,
  },

  props: {},
  data() {
    return {
      headers: [
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'unique_id',
          label: 'Unique ID',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'meta.recorded_at',
          label: 'Recorded On',
        },
        {
          key: 'job_type',
          label: "Session Type"
        },
        {
          key: 'user.name',
          label: "User"
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      jobs: [],
      users: [],
      userSearch: "",
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 15,
        search: '',
        status: '',
        job_type: '',
        user_uid: '',
      },
      total_count: 0,
      timeout: null,
    };
  },
  mounted() {
    this.getJobs();
    if (this.$can('Read', 'User') && this.$can('ReadAll', 'Scribe')) {
      this.getUsers();
    }
  },
  methods: {
    getMoreJobs(page) {
      this.filters.page = page - 1 // api index starts at 0, bootstrap starts at 1 lol
      this.getJobs()
    },
    async getJobs() {
      try {
        let res = await PurpleScribeService.list(this.filters)
        // Loop over and convert meta from json string if it is present
        this.jobs = res.data.data.map(j => {
          let metaJson = {
            prison_number: '',
            case_worker_name: '',
            prisoner_name: '',
            recorded_at: '',
          }
          try {
            metaJson = JSON.parse(j.meta)
          } catch(_) {}

          return {
            ...j,
            meta: metaJson
          }
        })
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      } catch(err) {
        const res = err.response
        let errorText = 'Could not get Purple Scribe sessions, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    async searchUsers() {
      await this.getUsers();
    },
    async getUsers() {
      UserService.getUsers({
        page: 0,
        page_size: 30,
      }).then(res => {
        this.users = res.data.data;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get users, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
        status: '',
      }

      this.getJobs();
    },
    showScribeModal(modalName) {
      this.$bvModal.show(modalName);
    },
    closeModals() {
      this.$bvModal.hide('modal-create-scribe-job');
    },
    refreshScribeJobs() {
      this.closeModals();
      this.getMoreJobs(1);
    },
  },
};
</script>
